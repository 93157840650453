<section class="page-title-area">
    <div class="container">
        <div class="page-title-content text-center" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="projects-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let singleProjectsContent of singleProjectsBox;">
                <div class="single-projects-box">
                    <div class="image">
                        <img [src]="singleProjectsContent.img" alt="image">

                        <a routerLink="/{{singleProjectsContent.link}}" class="link-btn"><i class='{{singleProjectsContent.icon}}'></i></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/{{singleProjectsContent.link}}">{{singleProjectsContent.title}}</a></h3>
                        <span>{{singleProjectsContent.subTitle}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>