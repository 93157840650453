<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="faq-area ptb-100">
    <div class="container">
        <div class="faq-accordion">
            <ul class="accordion">
                <li class="accordion-item" *ngFor="let Accordion of faqAccordion;">
                    <a class="accordion-title" href="javascript:void(0)">
                        <i class='bx bx-chevron-down'></i>
                        {{Accordion.questions}}
                    </a>

                    <div class="accordion-content">
                        <p>{{Accordion.answer}}</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</section>

<app-project-start></app-project-start>