import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services-details-page',
    templateUrl: './services-details-page.component.html',
    styleUrls: ['./services-details-page.component.scss']
})
export class ServicesDetailsPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Detalle de Servicios',
            subTitle: 'Analítica de Datos',
        }
    ]
    servicesDetailsImage: DetailsImage[] = [
        {
            img: 'assets/img/services/services-details1.jpg'
        }
    ]
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'Big Data',
            title1: 'Acerca de estos servicios',
            paragraphText1: 'El procesamiento de grandes volumenes de datos provenientes de diversas fuentes (estructurados, no estructurados y semi estrucutrados) permiten enriquecer el análisis y la toma de decisiones. Mediante el análisis descriptivo y predictivo (modelos de analítica avanzada) podemos generar conocimientos más profundos del negocio.',
            paragraphText2: 'La mayor facilidad para el acceso a capacidad de procesamiento (en nube) permite el uso de modelos avanzados de analítica (modelos predictivos) y también el uso de una gran variedad de información semi estructurada y no estructurada para enriquecer estos mismos modelos a un costo acequible para la mayoria de empresas. Así mismo estas mismas tecnologías nos permiten integrar la información del neocio de una forma mas rápida y flexible.',
            title2: 'Conceptos Importantes',
            title3: 'Áreas de Aplicación',
            title4: 'Technologías a considerar',
            img1: 'assets/img/projects/img2.jpg',
            img2: 'assets/img/services/charts.jpg'
        }
    ]
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'Data Movement: ETL, ELT, CDC'
        },
        {
            title: 'Data pipeline'
        },
        {
            title: 'Data Lake'
        },
        {
            title: 'Data Warehouse'
        },
        {
            title: 'Stream Analytics'
        },
        {
            title: 'Machine Learning'
        }
    ]
    servicesDetailsDescApplication: Application[] = [
        {
            title: 'Manufactura',
            icon: 'flaticon-factory'
        },
        {
            title: 'Salud',
            icon: 'flaticon-hospital'
        },
        {
            title: 'Automotriz',
            icon: 'flaticon-tracking'
        },
        {
            title: 'Banca',
            icon: 'flaticon-investment'
        },
        {
            title: 'Inmobiliario',
            icon: 'flaticon-house'
        },
        {
            title: 'Logística',
            icon: 'flaticon-order'
        }
    ]
    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: 'Spark'
        },
        {
            title: 'Hadoop'
        },
        {
            title: 'Kafka'
        },
        {
            title: 'SQL'
        },
        {
            title: 'NoSQL'
        },
        {
            title: 'Python'
        },
        {
            title: 'R'
        },
        {
            title: 'Powerbi'
        }
    ]

    sidebarServicesList: ServicesList[] = [
        {
            title: 'Big Data',
            link: 'services-details'
        },
        {
            title: 'Data Warehousing',
            link: 'services-details'
        },
        {
            title: 'Business Intelligence',
            link: 'services-details'
        },
        {
            title: 'Data Visualization',
            link: 'services-details'
        },
        {
            title: 'Data Quality',
            link: 'services-details'
        },
        {
            title: 'Data Governance',
            link: 'services-details'
        }
    ]
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: 'PDF Download',
            icon: 'bx bxs-file-pdf',
            link: 'services-details'
        },
        {
            title: 'Services Details.txt',
            icon: 'bx bxs-file-txt',
            link: 'services-details'
        }
    ]
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Teléfeno',
            subTitle: '+51 992589859'
        },
        {
            icon: 'bx bx-map',
            title: 'Ubicación',
            subTitle: 'Lima, Perú'
        },
        {
            icon: 'bx bx-envelope',
            title: 'Email',
            subTitle: 'info@mazamariit.pe'
        }
    ]
    
}
class pageTitle {
    title : string;
    subTitle : string;
}
class DetailsImage {
    img : string;
}
class DetailsDesc {
    subTitle : string;
    title1 : string;
    title2 : string;
    title3 : string;
    title4 : string;
    paragraphText1 : string;
    paragraphText2 : string;
    img1 : string;
    img2 : string;
}
class ImportantFacts {
    title : string;
}
class Application {
    title : string;
    icon: string;
}
class TechnologiesFeatures {
    title : string;
}

class ServicesList {
    title : string;
    link : string;
}
class DownloadFile {
    title : string;
    icon : string;
    link : string;
}
class ContactInfo {
    icon : string;
    title : string;
    subTitle : string;
}