<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>
    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box" *ngFor="let InfoBox1 of contactInfoBox1;">
                    <div class="back-icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <h3>{{InfoBox1.title}}</h3>
                    <p>{{InfoBox1.location}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box" *ngFor="let InfoBox2 of contactInfoBox2;">
                    <div class="back-icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <h3>{{InfoBox2.title}}</h3>
                    <p>Mobile: <a href="tel:{{InfoBox2.number}}">{{InfoBox2.number}}</a></p>
                    <p>E-mail: <a href="mailto:{{InfoBox2.email}}">{{InfoBox2.email}}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="contact-info-box" *ngFor="let InfoBox3 of contactInfoBox3;">
                    <div class="back-icon">
                        <i class='{{InfoBox3.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox3.icon}}'></i>
                    </div>
                    <h3>{{InfoBox3.title}}</h3>
                    <p>{{InfoBox3.text1}}</p>
                    <p>{{InfoBox3.text2}}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{sectionTitleContent.subTitle}}</span>
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-image" *ngFor="let Image of contactImage;">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="contactForm" [formGroup]="contactForm" (submit)="register()">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="name" name="name" class="form-control" id="name" placeholder="Nombre">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" formControlName="email" name="email" class="form-control" id="email" placeholder="Dirección email">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="phone_number" name="phone_number" class="form-control" id="phone_number" placeholder="Teléfono">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea formControlName="message" name="message" id="message" class="form-control" cols="30" rows="6" placeholder="Escriba un mensaje..."></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" [disabled]="contactForm.invalid" class="default-btn"><i class="flaticon-tick"></i>{{buttonText}}<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.4851785779365!2d-77.04677318613382!3d-12.078901545785554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c8f09927c57f%3A0xd1054703db319747!2zSmlyw7NuIENhaHVpZGUgOTAwLCBKZXPDunMgTWFyw61hIDE1MDcy!5e0!3m2!1ses!2spe!4v1656455973209!5m2!1ses!2spe" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>