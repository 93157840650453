<section class="what-we-do-area bg-fafafb pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let whatWeDoContent of singleWhatWeDoBox;">
                <div class="single-what-we-do-box">
                    <div class="icon">
                        <i class="{{whatWeDoContent.icon}}"></i>
                    </div>
                    <h3><a routerLink="/{{whatWeDoContent.link3}}">{{whatWeDoContent.title}}</a></h3>
                    <p>{{whatWeDoContent.paragraphText}}</p>
                    <a routerLink="/{{whatWeDoContent.link1}}" class="link">{{whatWeDoContent.linkText1}} <i class="flaticon-right"></i></a>
                    <a routerLink="/{{whatWeDoContent.link2}}" class="link">{{whatWeDoContent.linkText2}} <i class="flaticon-right"></i></a>
                    <a routerLink="/{{whatWeDoContent.link3}}" class="read-more-btn"><i class="flaticon-right"></i>{{whatWeDoContent.linkText3}}</a>
                </div>
            </div>
        </div>
    </div>
</section>