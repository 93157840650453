import { Component, OnInit, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root' 
  })

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    buttonText = "Enviar";

    contactForm = new FormGroup({
        name: new FormControl('',[Validators.required]),
        email: new FormControl('',[Validators.required, Validators.email]),
        phone_number: new FormControl('',[Validators.required]),
        message: new FormControl('')
    })

    constructor(private http: HttpClient) { }

    ngOnInit(): void {
        
    }

    register(): any {
        
        console.log(this.contactForm.value);
        this.buttonText = "Enviando...";
        let user = {
            name : this.contactForm.controls['name'].value,
            email: this.contactForm.controls['email'].value,
            phone_number: this.contactForm.controls['phone_number'].value,
            message: this.contactForm.controls['message'].value
        }

        this.http.post<any>("http://localhost:3000/sendmail", user).subscribe(
            respuesta => {
              console.log(` ${user.name} is successfully register and mail has been sent and the message id is ${respuesta.messageId}`);
              this.contactForm.reset();
            },
            err => {
              console.log(err);
              this.buttonText = "Enviar";
            },() => {
              this.buttonText = "Enviar";
            }
        )
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Contáctenos'
        }
    ]
    contactInfoBox1: InfoBox1[] = [
        {
            icon: 'bx bx-map',
            title: 'Dirección',
            location: 'Jirón Cahuide 900, 6B - 401, Jesús María'
        }
    ]
    contactInfoBox2: InfoBox2[] = [
        {
            icon: 'bx bx-phone-call',
            title: 'Contacto',
            number: '+51 992589859',
            email: 'info@mazamariit.pe'
        }
    ]
    contactInfoBox3: InfoBox3[] = [
        {
            icon: 'bx bx-time-five',
            title: 'Horario de atención',
            text1: 'Lunes - Viernes: 09:00 - 18:00',
            text2: ''
        }
    ]

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Ponte en contacto",
            title: '¿Listo para empezar?',
            paragraphText: 'Su dirección de correo no será publicada. Campos obligatorios marcados en *'
        }
    ]
    contactImage: Image[] = [
        {
            img: 'assets/img/contact.png'
        }
    ]

}
class pageTitle {
    title : string;
}
class InfoBox1 {
    icon : string;
    title : string;
    location : string;
}
class InfoBox2 {
    icon : string;
    title : string;
    number : string;
    email : string;
}
class InfoBox3 {
    icon : string;
    title : string;
    text1 : string;
    text2 : string;
}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class Image {
    img : string;
}

// Se agrega la funcion