<section class="our-mission-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="our-mission-content">
                    <div class="content" *ngFor="let Content of ourMissionContent;">
                        <h2>{{Content.title}}</h2>
                        <p>{{Content.paragraphText1}}</p>
                        <ul class="features-list">
                            <li *ngFor="let List of featuresList;"><i class="{{List.icon}}"></i> {{List.title}}</li>
                        </ul>
                        <p>{{Content.paragraphText2}}</p>
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="our-mission-image" *ngFor="let Image of ourMissionImage;">
                    <img [src]="Image.img" alt="image">
                    <div class="shape"><img src="assets/img/our-mission/shape1.png"></div>
                </div>
            </div>
        </div>
    </div>
</section>