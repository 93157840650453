<section class="process-area ptb-100 bg-fafafb">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{sectionTitleContent.subTitle}}</span>
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>

        <div class="row align-items-center m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="process-image" *ngFor="let Image of processImage;">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="process-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6" *ngFor="let Content of processContent;">
                            <div class="single-box-item">
                                <div class="d-flex align-items-center">
                                    <div class="image">
                                        <img [src]="Content.img" alt="image">
                                    </div>
                                    <h3>{{Content.title}}</h3>
                                    <div class="number">{{Content.number}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>