<section class="featured-services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let featuredServicesContent of singleFeaturedServicesBox;">
                <div class="single-featured-services-box">
                    <div class="icon">
                        <i class="{{featuredServicesContent.icon}}"></i>
                    </div>
                    <h3><a routerLink="/{{featuredServicesContent.link}}">{{featuredServicesContent.title}}</a></h3>
                    <p>{{featuredServicesContent.paragraphText}}</p>
                    <a routerLink="/{{featuredServicesContent.link}}" class="read-more-btn"><i class="{{featuredServicesContent.linkIcon}}"></i>{{featuredServicesContent.linkText}}</a>
                </div>
            </div>
        </div>
    </div>
</section>