import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homefour-services',
    templateUrl: './homefour-services.component.html',
    styleUrls: ['./homefour-services.component.scss']
})
export class HomefourServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Servicios con los que podemos ayudarlo',
            paragraphText: 'Servicios que se adaptan a las necesidades de su negocio para una correcta toma de deciciones basadas en datos.'
        }
    ]
    singleServicesBoxItem: ServicesBoxContent[] = [
        {
            icon: 'assets/img/services/icon7.png',
            title: 'Big Data Analysis',
            paragraphText: 'Análisis de datos con gran volumen, multi estructurado y variable.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon8.png',
            title: 'Data Warehousing',
            paragraphText: 'Maneje un repositorio de datos integral estructurado, transversal a su organización.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon9.png',
            title: 'Business Intelligence',
            paragraphText: 'Soporte los procesos de negocio con indicadores clave y respaldados con datos fiables.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon10.png',
            title: 'Data Visualization',
            paragraphText: 'Encuentra la mejor manera de comunicar sus resultados basados en datos con herramientas visuales de fácil entendimiento y orientado al descubrimiento de nuevas perspectivas.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon11.png',
            title: 'Data Quality',
            paragraphText: 'Asegurece que sus datos cuentan con el nivel de calidad adecuado para su proceso de negocio.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon12.png',
            title: 'Data Governance',
            paragraphText: 'Controle y gestione sus activos de datos, establezca políticas y buenas practicas sobre procesos que involucran datos.',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class ServicesBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    linkText : string;
    linkIcon : string;
    link : string;
}