import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aboutpage-about',
    templateUrl: './aboutpage-about.component.html',
    styleUrls: ['./aboutpage-about.component.scss']
})
export class AboutpageAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/about/img1.png'
        }
    ]
    aboutContent: Content[] = [
        {
            subTitle: 'Acerca de Nosotros',
            title: 'Dirígete a la revolución digital a través de la analítica de datos',
            paragraphText1: 'Trabajamos en conjunto para diseñar e implementar la hoja de ruta hacia un proceso de gestión de datos analíticos maduro.',
            paragraphText2: 'Nuestro equipo garantiza el éxito de nuestros proyectos.'
        }
    ]
    featuresList: List[] = [
        {
            icon: 'assets/img/icon1.png',
            title: '5 Años',
            subTitle: 'En el mercado'
        },
        /*{
            icon: 'assets/img/icon2.png',
            title: '10+',
            subTitle: 'Equipo de trabajo'
        },*/
        {
            icon: 'assets/img/icon3.png',
            title: '100%',
            subTitle: 'Tasa de satisfacción'
        },
        {
            icon: 'assets/img/icon4.png',
            title: '80%',
            subTitle: 'Consultores Senior'
        }
    ]
    aboutText: Text[] = [
        /*{
            title: 'Nuestra Historia',
            paragraphText: 'Un equipo altamente calificado para ayudar a cerrar la brecha presente en los procesos analíticos.',
            featuresList1: 'Capacidad Técnica',
            featuresList2: 'Felixibilidad tecnológica',
            featuresList3: 'Trabajo en equipo',
            featuresList4: 'Mejora constante',
            icon: 'flaticon-tick'
        },*/
        {
            title: 'Nuestra Misión',
            paragraphText: 'Ayudarte a tomar las mejores decisiones basadas en datos a través de la tecnología y los procesos adecuados.',
            featuresList1: 'Priorizar necesidades',
            featuresList2: 'Establecer objetivos',
            featuresList3: 'Tecnología correcta',
            featuresList4: 'Equipo correcto',
            icon: 'flaticon-tick'
        },
        {
            title: 'Quienes Somos',
            paragraphText: 'Un equipo altamente calificado para ayudar a cerrar la brecha presente en los procesos analíticos.',
            featuresList1: 'Capacidad Técnica',
            featuresList2: 'Felixibilidad tecnológica',
            featuresList3: 'Trabajo en equipo',
            featuresList4: 'Mejora constante',
            icon: 'flaticon-tick'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
}
class List {
    icon : string;
    title : string;
    subTitle : string;
}
class Text {
    title : string;
    paragraphText : string;
    featuresList1 : string;
    featuresList2 : string;
    featuresList3 : string;
    featuresList4 : string;
    icon : string;
}