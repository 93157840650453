import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homefour-featured-services',
    templateUrl: './homefour-featured-services.component.html',
    styleUrls: ['./homefour-featured-services.component.scss']
})
export class HomefourFeaturedServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'Cómo llevamos su negocio de bueno a excelente',
            paragraphText: 'Establecemos procesos análiticos basado en sus necesidades, de manera escalable, alineados a las mejores practicas y soportados por la mejor tecnología.'
        }
    ]
    singleFeaturedServicesBox: featuredServicesContent[] = [
        {
            icon: 'flaticon-analysis',
            title: 'Analice sus necesidades de datos',
            paragraphText: 'Priorice sus procesos de negocio y cómo afecta el tratamiento de los datos a estos.',
            linkIcon: 'flaticon-right',
            linkText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'flaticon-structure',
            title: 'Desarrolle un plan a medida',
            paragraphText: 'Defina una hoja de ruta que se ajuste a sus infraestructura y sus recursos.',
            linkIcon: 'flaticon-right',
            linkText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'flaticon-idea',
            title: 'Implemente su solución',
            paragraphText: 'Empiece a tomar decisiones con datos fiables en el menor tiempo posible con proyectos progresivos.',
            linkIcon: 'flaticon-right',
            linkText: 'Read More',
            link: 'services-details'
        }
    ]

}
class sectionTitleContent {
    title : string;
    paragraphText : string;
}
class featuredServicesContent {
    icon : string;
    title : string;
    paragraphText : string;
    linkIcon : string;
    linkText : string;
    link : string;
}