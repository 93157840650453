import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services-page',
    templateUrl: './services-page.component.html',
    styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Servicios'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-rocket',
            title: 'Big Data Analysis',
            paragraphText: 'Análisis de datos con gran volumen, multi estructurado y variable.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-laptop',
            title: 'Data Warehousing',
            paragraphText: 'Maneje un repositorio de datos integral estructurado, transversal a su organización.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-money',
            title: 'Business Intelligence',
            paragraphText: 'Soporte los procesos de negocio con indicadores clave y respaldados con datos fiables.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-analytics',
            title: 'Data Visualization',
            paragraphText: 'Encuentra la mejor manera de comunicar sus resultados basados en datos con herramientas visuales de fácil entendimiento y orientado al descubrimiento de nuevas perspectivas.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-settings',
            title: 'Data Quality',
            paragraphText: 'Asegurece que sus datos cuentan con el nivel de calidad adecuado para su proceso de negocio.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-segmentation',
            title: 'Data Governance',
            paragraphText: 'Controle y gestione sus activos de datos, establezca políticas y buenas practicas sobre procesos que involucran datos.',
            link: 'services-details',
            linkText: 'View Details'
        }
    ]

}
class pageTitle {
    title : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}