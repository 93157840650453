import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homefour-why-choose-us',
    templateUrl: './homefour-why-choose-us.component.html',
    styleUrls: ['./homefour-why-choose-us.component.scss']
})
export class HomefourWhyChooseUsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    whyChooseUsContent: Content[] = [
        {
            title: '¿Porqué Elegirnos?',
            paragraphText1: 'En nuestros años de experiencia en data analytics, construimos competencias comprehensivas que cubren la gestión de datos, BI (incluyendo self-service BI y data visualization) y analítica avanzada tradicional y bigdata.',
            paragraphText2: 'Iniciamos en 2019 con un equipo técnico con más de 10 años de experiencia en proyectos de análitica de datos y con el objetivo de llenar las brechas existentes en el mercado.',
            linkIcon: 'flaticon-right',
            linkText: 'Más de nosotros',
            link: 'about-us'
        }
    ]
    ourBrandPartnersList: List[] = [
        {
            title: 'Business Analytics para soluciones basadas en datos ',
            linkText: 'View All',
            link: 'partner'
        }
    ]
    brandPartnersImg: Image[] = [
        {
            img: 'assets/img/partner/img1.png'
        },
        {
            img: 'assets/img/partner/img2.png'
        },
        {
            img: 'assets/img/partner/img3.png'
        },
        {
            img: 'assets/img/partner/img4.png'
        },
        {
            img: 'assets/img/partner/img5.png'
        }
    ]

}
class Content {
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    linkIcon : string;
    linkText : string;
    link : string;
}
class List {
    title : string;
    linkText : string;
    link : string;
}
class Image {
    img : string;
}